import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import Footer2 from "../components/Footer2";
import Header2 from "../components/Header2";
import MetamaskModal from "../components/MetamaskModal";
import useLink from "../hooks/useLink";
import useTranslation from "../hooks/useTranslation";
import { connectWallet } from "../utils/connectWallet";

export default function Account()
{
    useLink(`${process.env.PUBLIC_URL}/eth.css`)
    const [isShow,setIsShow] = useState(false)
    const [errorMessage, setErrorMessage] = useState();
    const {t} = useTranslation()

    return <>
    <Header2 />
    <div data-v-f1034fc0="" className="custom-margin">
    <section data-v-f1034fc0="" class="main-content section">
                    <div data-v-f1034fc0="" class="container">
                        <div data-v-f1034fc0="" class="account custom-margin">
                            <div class="wallet-account">
                                <h2 class="title">
                                {t('cash.wallet')}
                                </h2>
                                <div class="account-box">
                                    <div class="address">
                                        <div class="address-item">
                                            <div class="label">{t('cash.Connected_Web3_wallet')}</div>
                                            <div class="value">-</div>
                                        </div>
                                    </div>
                                    <div class="action">
                                        <div class="action-item"><span class="icon is-large"><i
                                                    class="trnd trnd-account-wallet trnd-48px"></i></span>
                                            <div class="desc">
                                                {t('cash.Connect_your_web3_wallet')}
                                            </div> <button type="button" class="button is-primary is-outlined connect-wallet" onClick={()=>setIsShow(true)}>
                                                <span>{t('cash.Connect_Web3')}</span>
                                            </button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>
    </div>
    <Footer2/>
    <MetamaskModal isShow={isShow} setIsShow={setIsShow} setErrorMessage={setErrorMessage} />

    </>
}