import { useWeb3React } from "@web3-react/core";
import { Link, NavLink } from "react-router-dom"
import { connectWallet } from "../utils/connectWallet";
import {  logo,icon,privacy_img  } from "./Images"
import { memo , useState } from "react"
import useTranslation from "../hooks/useTranslation";

function Box(){
  const {t} = useTranslation();
    return(
        <div className="stats columns is-justify-content-space-between">
        <div className="column is-block">
          <div className="stat-box"><span className="icon"><i className="trnd-eth trnd-24px"><img src={icon} alt=""/></i></span>
            <div className="value">0</div>
            <div className="label">{t('deposited')}</div>
          </div>
        </div>
        <div className="column is-block">
          <div className="stat-box"><span className="icon"><i className="trnd trnd-user trnd-24px"></i></span>
            <div className="value">0</div>
            <div className="label">{t('unique_user')}</div>
          </div>
        </div>
        <div className="column is-block">
          <div className="stat-box"><span className="icon"><i className="trnd trnd-deposit trnd-24px"></i></span>
            <div className="value">0</div>
            <div className="label">{t('total_deposits')}</div>
          </div>
        </div>
      </div>
    );
}

export default memo(Box);