import useToggle from "../hooks/useToggle"
import useTranslation from "../hooks/useTranslation"

function Footer2(){
  const {language, setLanguage, t} = useTranslation()
  const [dropdown,setDropdown] = useToggle(false)
  const closeDropdown = (e)=>{
    setDropdown(false);
    setLanguage(e)
    window.location.reload()
  }
    return <>
    <footer data-v-f1034fc0="" class="footer">
          <div class="container">
            <div class="level">
              <div class="level-left">
                <div class="level-item is-column">
                  <div class="level-subitem footer-address">
                    <div class="footer-address__name">
                      {t('cash.donation_address')}:
                    </div> <a target="_blank" href="https://etherscan.io/address/OX97b6c7B5Ff795C39d3ca03479746E45038c8e4F" rel="noreferrer" class="footer-address__value">OX97b6c7B5Ff795C39d3ca03479746E45038c8e4F</a>
                  </div>
                  <div class="level-subitem">
                    {t('title') +" "+t('cash.version')} :
                    <span class="footer-version__value">v1</span>
                  </div>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item is-column">
                  <div class="level-subitem">
                    <div class="buttons"><a type="button" href="https://explore.duneanalytics.com/public/dashboards/UEU02CHiGtNw9crfeD6OJ7bKPnvFtNjOgZ7Vc6uj" target="_blank" rel="noreferrer" class="button is-icon">
                        <span class="icon is-small"><i class="trnd trnd-stats"></i></span>
                      </a> <a type="button" href="https://torn.community/" target="_blank" rel="noreferrer" class="button is-icon">
                        <span class="icon is-small"><i class="trnd trnd-discourse"></i></span>
                      </a> <a type="button" href="https://discord.com/invite/TFDrM8K42j" target="_blank" rel="noreferrer" class="button is-icon">
                        <span class="icon is-small"><i class="trnd trnd-discord"></i></span>
                      </a> <a type="button" href="https://tornado-cash.medium.com/" target="_blank" rel="noreferrer" class="button is-icon">
                        <span class="icon is-small"><i class="trnd trnd-medium"></i></span>
                      </a> <a type="button" href="https://twitter.com/TornadoCash" target="_blank" rel="noreferrer" class="button is-icon">
                       
                       <span class="icon is-small"><i class="trnd trnd-twitter"></i></span>
                      </a> <a type="button" href="https://t.me/TornadoCashOfficial" target="_blank" rel="noreferrer" class="button is-icon">
                        <span class="icon is-small"><i class="trnd trnd-telegram"></i></span>
                      </a> <a type="button" href="https://github.com/tornadocash" target="_blank" rel="noreferrer" class="button is-icon">
                        <span class="icon is-small"><i class="trnd trnd-github"></i></span>
                      </a>
                      <div class="break"></div>
                      <div class="dropdown dropdown-menu-animation dropdown-langs is-top-left is-mobile-modal">
                        <div role="button" tabindex="0" aria-haspopup="true" class="dropdown-trigger">
                          <button type="button" class="button is-icon" onClick={setDropdown}>
                            <span><i className={"flag-icon is-active-locale-en "+(language == "en" ? "flag-icon-gb" : "flag-icon-cn")}></i></span>
                          </button></div>
                          <div class={"dropdown-menu "+(!dropdown && "hide" )} >
                          <div role="list" class="dropdown-content"> 
                          <a role="listitem" tabindex="0" className={"dropdown-item "+(language == "en" && "is-active")} onClick={()=>closeDropdown('en')}><i class="flag-icon flag-icon-gb"></i>
                            EN
                          </a><a role="listitem" tabindex="0" className={"dropdown-item "+(language == "cn" && "is-active")} onClick={()=>closeDropdown('cn')}><i class="flag-icon flag-icon-cn"></i>
                            CN
                          </a></div></div>
                    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
    </>
}
export default Footer2