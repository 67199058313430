import { useWeb3React } from "@web3-react/core";
import { Link, NavLink } from "react-router-dom"
import { connectWallet } from "../utils/connectWallet";
import {  logo,icon,logo2  } from "./Images"
import { memo , useEffect, useState } from "react"
import useToggle from "../hooks/useToggle";
import useTranslation from "../hooks/useTranslation";
function Header() {
  const [nav, setNav] = useToggle(false)
  const {t} = useTranslation()
  // useEffect(()=>{
  //   setLanguage('ch')
  // },[])
    return (
        <nav role="navigation" aria-label="main navigation" className="navbar header">
          <div className="container">
            <div className="navbar-brand"><a  aria-current="page"
                className="navbar-item is-active ">
                {/* <div className="logo"></div> */}
                <img src={logo2} alt="" className="logo"/>
              </a><a role="button" aria-label="menu" className="navbar-burger burger" onClick={() => setNav()}><span
                  aria-hidden="true"></span><span aria-hidden="true"></span><span aria-hidden="true"></span></a></div>
            <div className={"navbar-menu "+(nav && "show")}>
              <div className="navbar-start"><a target="_blank" rel="noreferrer"
                  className="navbar-item">
                  {t('nav.audit')}
                </a> <a  className="navbar-item">
                {t('nav.roadmap')}
                </a> <a  target="_blank" rel="noreferrer"
                  className="navbar-item">
                   {t('nav.whitepaper')}
                </a> <a rel="noreferrer" className="navbar-item">
                {t('nav.bsc_scan')}
                </a> <a  target="_blank" rel="noreferrer"
                  className="navbar-item">  {t('nav.presale_link')}</a>
                    </div>
              <div className="navbar-end">
                <div className="navbar-item">
                  <div className="buttons"><a href={'/cash'} target="_blank"
                      rel="noreferrer" className="button is-primary is-outlined"><span className="icon is-small"><i
                          className="trnd trnd-BNB"><img src={icon} alt=""/></i></span><span>
                        {t('nav.launch_app')}
                      </span>
                     
                    </a></div>
                </div>
              </div>
            </div>
          </div>
        </nav>
    );
}

export default memo(Header);
