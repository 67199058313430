import { useWeb3React } from "@web3-react/core";
import { Link, NavLink } from "react-router-dom"
import { connectWallet } from "../utils/connectWallet";
import { banner_icon, icon } from "./Images"
import { memo, useEffect, useState } from "react"
import useToggle from "../hooks/useToggle";
import axios from "axios";
import useTranslation from "../hooks/useTranslation";

function Cash() {
  const [dropdown, setDropdown] = useState(false)
  const [tab, setTab] = useState('deposit')
  const [coin, setCoin] = useState('bnb')
  const [ip, setIP] = useState('');
  const {t} = useTranslation()

  const selectedCoin = (value) => {
    setDropdown(!dropdown)
    setCoin(value)
  }
  const getData = async () => {
  const res = await axios.get('https://geolocation-db.com/json/')
  setIP(res.data)
  }
  useEffect( () => {
  getData()
  }, [])
  return (
    <div data-v-f1034fc0="" className="custom-margin">

      <div className="columns">
        <div className="column is-half">
          <div className="b-tabs is-bnb">
            <nav role="tablist" aria-orientation="horizontal" className="tabs">
              <ul>
                <li role="presentation" className={"tab-li "+(tab == 'deposit' && "is-active")} onClick={(e)=>setTab('deposit')}><a role="tab" className="tab-btn" id="108-tab"
                  data-tabname="108-content" aria-selected="true" tabindex="0">
                  <span>{t('cash.deposit')}</span>
                </a></li>
                <li role="presentation" className={"tab-li "+(tab == 'withdraw' && "is-active")} onClick={(e)=>setTab('withdraw')}><a role="tab" className="tab-btn" id="133-tab"
                  data-tabname="133-content" aria-selected="false" tabindex="-1">
                  <span>{t('cash.withdraw')}</span>
                </a></li>
              </ul>
            </nav>
            <section className="tab-content">
              <div className="tab-item" role="tabpanel" id="108-content" aria-labelledby="108-label" tabindex="0"
              style={tab == 'withdraw' ? { display: "none" } : { display: "block" }} >
                <fieldset>
                  <div className="field"><label className="label">{t('cash.token')}</label>
                    <div className="dropdown dropdown-menu-animation is-mobile-modal is-expanded">
                      <div role="button" tabindex="0" aria-haspopup="true"
                        className="dropdown-trigger token-dp-btn" onClick={(e) => setDropdown(!dropdown)}>
                        <div className="control">
                          <div className="input"><span>BNB</span></div>
                        </div>
                      </div>
                      {/* <div aria-hidden="true" className="background" ></div> */}
                      <div aria-hidden="true" className={"dropdown-menu token-dp-menu " + (dropdown ? "show" : "hide")} >
                        <div role="list" className="dropdown-content">
                          <a role="listitem" tabindex="0" className={"dropdown-item " + (coin == 'bnb' && "is-active")} onClick={() => selectedCoin('bnb')}>
                            BNB
                          </a>
                          <a role="listitem" tabindex="0"
                            className={"dropdown-item " + (coin == 'eth' && "is-active")} onClick={() => selectedCoin('eth')}>
                            ETH
                          </a><a role="listitem" tabindex="0" className={"dropdown-item " + (coin == 'btc' && "is-active")} onClick={() => selectedCoin('btc')}>
                            BTC
                          </a></div>
                      </div>
                    </div>

                  </div>
                  <div className="field"><label className="label">
                  {t('cash.amount')}
                    {/* <span className="b-tooltip is-primary is-right is-small is-multiline">
                      <div className="tooltip-content" >Each amount is a standalone Tornado
                        Cash instance with a separate anonymity set. Check the stats tab for more info.</div>
                      <div className="tooltip-trigger"><button className="button is-primary has-icon"><span
                        className="icon icon-info"></span></button></div>
                    </span> */}
                  </label>
                    <div className="b-steps is-small">
                      <nav className="steps is-animated is-rounded">
                        <ul className="step-items" id="bnb">
                          <li className="step-item is-active"><a className="step-link is-clickable"
                            data-tokentype="bnb_01">
                            <div className="step-marker">

                            </div>
                            <div className="step-details"><span className="step-title">1 BNB</span></div>
                          </a></li>
                          <li className="step-item is-previous"><a className="step-link is-clickable"
                            data-tokentype="bnb_1">
                            <div className="step-marker">

                            </div>
                            <div className="step-details"><span className="step-title">10 BNB</span></div>
                          </a></li>
                          <li className="step-item is-previous"><a className="step-link is-clickable"
                            data-tokentype="bnb_10">
                            <div className="step-marker">

                            </div>
                            <div className="step-details"><span className="step-title">100 BNB</span></div>
                          </a></li>
                          <li className="step-item"><a className="step-link is-clickable" data-tokentype="bnb_100">
                            <div className="step-marker">

                            </div>
                            <div className="step-details"><span className="step-title">1000 BNB</span></div>
                          </a></li>
                        </ul>
                      </nav>
                      <section className="step-content">
                      </section>

                    </div>

                  </div>
                </fieldset> <a type="button" className="button is-primary is-fullwidth" href="account.html">
                  <span>
                  {t('cash.connect')}
                  </span>

                </a>
              </div>
              <div data-v-34d2fd76="" className="tab-item" role="tabpanel" id="133-content"
                aria-labelledby="133-label" tabindex="-1" style={tab == 'deposit' ? { display: "none" } : { display: "block" }}>
                <div data-v-34d2fd76="" className="field">
                  <div data-v-34d2fd76="" className="label-with-buttons">
                    <div data-v-34d2fd76="" className="label">
                      {t('cash.note')}
                      {/* <span data-v-34d2fd76="" className="b-tooltip is-primary is-right is-small is-multiline">
                        <div className="tooltip-content" style={{ display: "none" }} >Please enter the note you received
                          when you made the deposit</div>
                        <div className="tooltip-trigger"><button data-v-34d2fd76=""
                          className="button is-primary has-icon"><span data-v-34d2fd76=""
                            className="icon icon-info"></span></button></div>
                      </span> */}
                    </div> <a data-v-34d2fd76="" style={{ display: "none" }} href="https://goerli.etherscan.io/tx/null" target="_blank"
                      className="button is-icon" ><span data-v-34d2fd76=""
                        className="b-tooltip is-primary is-left is-small is-multiline">
                        <div className="tooltip-content" style={{ display: "none" }}>Deposit transaction on Etherscan
                        </div>
                        <div className="tooltip-trigger"><svg data-v-34d2fd76="" xmlns="http://www.w3.org/2000/svg"
                          width="18" height="18" viewBox="0 0 40 40">
                          <path fill="#94FEBF" fill-rule="evenodd"
                            d="M36 40H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h8a2 2 0 1 1 0 4H6a2 2 0 0 0-2 2v28a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2v-6a2 2 0 1 1 4 0v8a4 4 0 0 1-4 4zm2-22a2 2 0 0 1-2-2V6.801l-3.601 3.602-6.162 6.162-3.834 3.834-7 7a2.004 2.004 0 0 1-2.833-2.833l7-7L33.136 4H24a2 2 0 1 1 0-4h13.897c.083-.004.161.008.243.014.165.012.324.043.476.093.054.018.107.027.159.049.227.096.431.235.606.403.005.005.012.006.018.011A1.993 1.993 0 0 1 40 2v14a2 2 0 0 1-2 2z">
                          </path>
                        </svg></div>
                      </span></a> <button data-v-34d2fd76="" className="button is-icon" style={{ display: "none" }}><span
                        data-v-34d2fd76="" className="b-tooltip is-primary is-right is-small is-multiline">
                        <div className="tooltip-content" style={{ display: "none" }} >Withdrawal settings</div>
                        <div className="tooltip-trigger"><svg data-v-34d2fd76="" xmlns="http://www.w3.org/2000/svg"
                          width="18" height="18" viewBox="0 0 40 34">
                          <path fill="#94FEBF" fill-rule="evenodd"
                            d="M38 7H22.859c-.447 1.722-1.997 3-3.859 3h-8c-1.862 0-3.412-1.278-3.859-3H2a2 2 0 1 1 0-4h5.141C7.588 1.278 9.138 0 11 0h8c1.862 0 3.412 1.278 3.859 3H38a2 2 0 1 1 0 4zM18 4h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2zM2 27h15.141c.447-1.722 1.997-3 3.859-3h8c1.862 0 3.412 1.278 3.859 3H38a2 2 0 1 1 0 4h-5.141c-.447 1.722-1.997 3-3.859 3h-8c-1.862 0-3.412-1.278-3.859-3H2a2 2 0 1 1 0-4zm20 3h6a1 1 0 0 0 0-2h-6a1 1 0 0 0 0 2z">
                          </path>
                        </svg></div>
                      </span></button>
                  </div>
                  <div data-v-34d2fd76="" className="control is-clearfix"><input type="text" autocomplete="on"
                    placeholder={t('cash.enter_note')} className="input" />
                  </div>
                  <div data-v-34d2fd76="" className="help">
                    <p data-v-34d2fd76=""></p>
                  </div>
                </div>

                <fieldset data-v-34d2fd76="">
                  <div data-v-34d2fd76="" className="field withdraw-address">
                    <div data-v-34d2fd76="" className="label-with-buttons">
                      <div data-v-34d2fd76="" className="label"><span data-v-34d2fd76="" className="name">
                        {t('cash.recipient')}
                      </span></div> <button data-v-34d2fd76="" className="button is-primary-text">
                      {t('cash.donate')}
                      </button>
                    </div>
                    <div data-v-34d2fd76="" className="control is-clearfix"><input type="text" autocomplete="on"
                      placeholder={t('cash.paste_address')} className="input" />



                    </div>
                    <p data-v-34d2fd76="" className="help"><span data-v-34d2fd76="" className="has-text-warning"></span>
                    </p>
                  </div>
                  <div data-v-34d2fd76="" className="field" style={{ display: "none" }}>
                    <div className="label">
                      Total
                    </div>
                    <div className="withdraw-data">

                      <div className="withdraw-data-item">
                        Gas Price
                        <span>4 Gwei</span>
                      </div>
                      <div className="withdraw-data-item">
                        Network fee
                        <span>0.00156 gETH</span>
                      </div>
                      <div className="withdraw-data-item">
                        Relayer fee
                        <span>0.05 ETH</span>
                      </div>
                      <div className="withdraw-data-item">
                        Total fee
                        <span>0.05156 ETH</span>
                      </div>

                      <hr />
                      <div className="withdraw-data-item">
                        Tokens to receive
                        <span>99.94844 ETH</span>
                      </div>

                    </div>
                  </div> <span data-v-34d2fd76=""
                    className="is-block b-tooltip is-primary is-top is-medium is-multiline">
                    <div className="tooltip-content" style={{ display: "none" }} >The total fee is higher than the amount
                      of tokens to be received. This could be due to high relayer's fee or temporary network
                      congestion.</div>
                    <div className="tooltip-trigger"><button data-v-34d2fd76="" type="button" disabled="disabled"
                      className="button slide-animation is-primary is-fullwidth">
                      <span>
                      {t('cash.withdraw')}
                      </span>

                    </button></div>
                  </span>
                </fieldset>
              </div>
            </section>
          </div>
          <div className="tab-with-corner is-left-bottom is-primary d-table"><span className="is-small"><a
            className="has-text-primary">BNB-1000.{t('title')}.BNB</a></span></div>
        </div>
        <div className="column is-half tab-section" id="bnb_section">
          <div className="box-stats" id="bnb_01">
            <div className="d-table tab-with-corner is-left-top">
              {t('cash.statistics')}
              <span className="selected"><span className="is-uppercase">1</span> BNB</span>
            </div>
            <div className="box">
              <div className="label">
                {t('cash.anonymity')}
                {/* <span className="b-tooltip is-primary is-top is-medium is-multiline">
                  <div className="tooltip-content" style={{ display: "none" }} >Number of deposits your withdrawal will
                    potentially originate from</div>
                  <div className="tooltip-trigger"><button className="button is-primary has-icon"><span
                    className="icon icon-info"></span></button></div>
                </span> */}
              </div>
              <div className="field"><span> <b>0</b> <span>{t('cash.equal_user')}</span></span>

              </div>
              <div className="label">{t('cash.latest_deposits')}</div>
              <div className="columns is-small is-multiline">
                <div className="column is-half-small">
                  <div className="deposits">
                    <div className="row">
                      <div className="value">1.</div>
                      <div className="data">{t('cash.nill_hours_ago')}</div>
                    </div>
                    <div className="row">
                      <div className="value">2.</div>
                      <div className="data">{t('cash.nill_hours_ago')}</div>
                    </div>
                    <div className="row">
                      <div className="value">3.</div>
                      <div className="data">{t('cash.nill_hours_ago')}</div>
                    </div>
                    <div className="row">
                      <div className="value">4.</div>
                      <div className="data">{t('cash.nill_hours_ago')}</div>
                    </div>
                    <div className="row">
                      <div className="value">5.</div>
                      <div className="data">{t('cash.nill_hours_ago')}</div>
                    </div>
                  </div>
                </div>
                <div className="column is-half-small">
                  <div className="deposits">
                    <div className="row">
                      <div className="value">6.</div>
                      <div className="data">{t('cash.nill_hours_ago')}</div>
                    </div>
                    <div className="row">
                      <div className="value">7.</div>
                      <div className="data">{t('cash.nill_hours_ago')}</div>
                    </div>
                    <div className="row">
                      <div className="value">8.</div>
                      <div className="data">{t('cash.nill_hours_ago')}</div>
                    </div>
                    <div className="row">
                      <div className="value">9.</div>
                      <div className="data">{t('cash.nill_hours_ago')}</div>
                    </div>
                    <div className="row">
                      <div className="value">10.</div>
                      <div className="data">{t('cash.nill_hours_ago')}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-ip"><span className="b-tooltip is-primary is-top is-large is-multiline">
             
                <div className="tab-with-corner is-right-bottom"><span className="is-small">{t('cash.your_ip')} <a target="_blank"
                  href="" rel="noreferrer" className="has-text-primary">
                    {ip.IPv4+', '+ip.city+', '+ip.country_code}
                </a>
                </span>

                </div>
            </span></div>
          </div>


        </div>

      </div>

    </div>
  )

}
export default memo(Cash);