import { useWeb3React } from "@web3-react/core";
import { Link, NavLink } from "react-router-dom"
import { connectWallet } from "../utils/connectWallet";
import {  logo,icon,privacy_img  } from "./Images"
import { memo , useState } from "react"
import useTranslation from "../hooks/useTranslation";
import parse from 'html-react-parser'

function Privacy() {
  const {t} = useTranslation()

    return (
        <div className="BNB-section how-to-achieve-privacy">
        <div className="columns is-vcentered">
          <div className="column">
            <figure className="image is-privacy"><img src={privacy_img}/></figure>
          </div>
          <div className="column has-text-left">
            <h2 className="title">{parse(t('achieve'))}</h2>
            <p className="subtitle">{parse(t('achieve_desc'))}</p>
          </div>
        </div>
      </div>
    );
}

export default memo(Privacy);