import { useWeb3React } from "@web3-react/core";
import { Link, NavLink } from "react-router-dom"
import { connectWallet } from "../utils/connectWallet";
import {  logo2,icon,privacy_img  } from "./Images"
import { memo , useState } from "react"
import MetamaskModal from "./MetamaskModal";
import useTranslation from "../hooks/useTranslation";

function Header2(){

    const [networkModal,setNetworkModal] = useState(false)
    const [network,setNetwork] = useState('Goerli')
    const [isShow,setIsShow] = useState(false)
    const [nav,setNav] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const {t} = useTranslation()

    return (
       <>
        <nav data-v-f1034fc0="" role="navigation" aria-label="main navigation" className="navbar header">
        <div className="container">
            <div className="navbar-brand"><Link to={'/cash'} className="navbar-item is-active">
              <img src={logo2} alt="" className="logo" />
            </Link><a role="button" aria-label="menu" className="navbar-burger burger" onClick={()=>setNav(!nav)}><span
                        aria-hidden="true"></span><span aria-hidden="true"></span><span
                        aria-hidden="true"></span></a></div>
            <div className="navbar-menu" style={nav ?{ display:"block"}:{ display:"none"}}>
                <div className="navbar-start"><a href="" aria-current="page" className="navbar-item">
                        {t('nav.whitepaper')}
                    </a>
                </div>
                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons"><button data-v-690521f0="" type="button"
                                className="button network-button modal-network-btn" onClick={(e)=>setNetworkModal(true)}><span
                                    className="icon is-small"><i
                                        className={"trnd "+(network == 'Goerli' ? "trnd-ethereum-goerli":'')}></i></span><span>{network}</span>
                                
                            </button> <span className="b-tooltip is-dark-tooltip is-bottom is-medium">
                                <div className="tooltip-content" style={{display: "none"}}>
                                    <p>{t('cash.not_connected')}</p> <button type="button"
                                        className="button is-primary-link mb-0 connect-wallet" onClick={(e)=>setIsShow(true)}>
                                        <span>{t('connect')}</span>
                                        
                                    </button>
                                </div>
                                <div className="tooltip-trigger"> <button type="button"
                                        className="button is-nav-icon"><span className="icon is-small"><i
                                                className="trnd trnd-metamask"></i></span>
                                        
                                        
                                    </button></div>
                            </span> <span className="b-tooltip is-dark-tooltip is-bottom is-medium">
                                <div className="tooltip-content" style={{display: "none"}}>
                                    <p>{t('cash.not_connected')}</p> <Link to={'/account'}
                                        className="button is-primary-link mb-0" href="account.html">
                                        <span>{t('cash.connect_note_account')}</span>
                                        
                                    </Link>
                                </div>
                                <div className="tooltip-trigger"> <button type="button"
                                        className="button is-nav-icon"><span className="icon is-small"><i
                                                className="trnd trnd-wallet"></i></span>
                                        
                                        
                                    </button></div>
                            </span> <Link to={'/account'} className="button is-primary is-outlined" ><span
                                    className="icon is-small"><i className="trnd trnd-settings"></i></span><span>
                                    {t('cash.settings')}
                                </span>
                                
                            </Link></div>
                    </div>
                </div>
            </div>
        </div>
       
       
    </nav>
    <div data-v-f1034fc0="" className="network has-background-warning modal-network-btn" style={{display: "none"}}>
        App network (5) doesn't mach to network selected in wallet.
        <button data-v-f1034fc0="" type="button" className="button ml-3 is-warning is-dark">
            <span>Change network</span>
            
        </button>
    </div>

    <div tabindex="-1" class={"modal "+(networkModal && "is-active")} id="network-modal" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-background"></div>
    <div class="animation-content" style={{maxWidth: "440px"}}>
      <div data-v-7e8f0ee7="" class="modal-card box box-modal is-wallet-modal" can-cancel="escape,button,outside">
        <header data-v-7e8f0ee7="" class="box-modal-header is-spaced">
          <p data-v-7e8f0ee7="" class="box-modal-title has-text-centered">Change network</p> <button data-v-7e8f0ee7="" type="button" class="delete" onClick={(e)=>setNetworkModal(false)}></button>
        </header>
        <div data-v-7e8f0ee7="" class="networks">
          <div data-networkname="Ethereum" data-v-7e8f0ee7="" class="item"><span data-v-7e8f0ee7="" class="icon network-icon"><i class="trnd trnd-ethereum-mainnet trnd-24px"></i></span> <b data-v-7e8f0ee7="">Ethereum
              Mainnet</b> <span data-v-7e8f0ee7="" class="network-checkbox"></span></div>
          <div data-v-7e8f0ee7="" data-networkname="BSC" class="item"><span data-v-7e8f0ee7="" class="icon network-icon"><i class="trnd trnd-binance-smart-chain trnd-24px"></i></span> <b data-v-7e8f0ee7="">Binance Smart Chain</b> <span data-v-7e8f0ee7="" class="network-checkbox"></span></div>
          <div data-v-7e8f0ee7="" data-networkname="xDAI" class="item"><span data-v-7e8f0ee7="" class="icon network-icon"><i class="trnd trnd-xdai-chain trnd-24px"></i></span> <b data-v-7e8f0ee7="">xDAI
              Chain</b>
            <span data-v-7e8f0ee7="" class="network-checkbox"></span>
          </div>
          <div data-v-7e8f0ee7="" data-networkname="Polygon" class="item"><span data-v-7e8f0ee7="" class="icon network-icon"><i class="trnd trnd-polygon-matic-network trnd-24px"></i></span>
            <b data-v-7e8f0ee7="">Polygon (Matic) Network</b> <span data-v-7e8f0ee7="" class="network-checkbox"></span>
          </div>
          <div data-v-7e8f0ee7="" data-networkname="Avalanche" class="item"><span data-v-7e8f0ee7="" class="icon network-icon"><i class="trnd trnd-avalanche-mainnet trnd-24px"></i></span> <b data-v-7e8f0ee7="">Avalanche Mainnet</b> <span data-v-7e8f0ee7="" class="network-checkbox"></span></div>
          <div data-v-7e8f0ee7="" data-networkname="Goerli" class="item is-active"><span data-v-7e8f0ee7="" class="icon network-icon"><i class="trnd trnd-ethereum-goerli trnd-24px"></i></span> <b data-v-7e8f0ee7="">Ethereum
              Goerli</b> <span data-v-7e8f0ee7="" class="network-checkbox"></span></div>
        </div>
      </div>
    </div>
  </div>
  <MetamaskModal isShow={isShow} setIsShow={setIsShow} setErrorMessage={setErrorMessage} />

       </>
    )
}

export default memo(Header2);