import { useWeb3React } from "@web3-react/core";
import { Link, NavLink } from "react-router-dom"
import { connectWallet } from "../utils/connectWallet";
import {  logo,icon,logo2  } from "./Images"
import { memo , useState } from "react"
import useTranslation from "../hooks/useTranslation";
import useToggle from "../hooks/useToggle";

function Footer(){
  const {language, setLanguage, t} = useTranslation()
  const [dropdown,setDropdown] = useToggle(false)
  const closeDropdown = (e)=>{
    setDropdown(false);
    setLanguage(e)
    window.location.reload()
  }
    return(
        <footer className="footer">
          <div className="container">
            <div className="level">
              <div className="level-left">
                <div className="level-item"></div>
              </div>
              <div className="level-right">
                <div className="level-item is-column">
                  <div className="level-subitem">
                    <div className="buttons"><a type="button"
                        href="https://explore.duneanalytics.com/public/dashboards/UEU02CHiGtNw9crfeD6OJ7bKPnvFtNjOgZ7Vc6uj"
                        target="_blank" rel="noreferrer" className="button is-icon">
                        
                        <span className="icon is-small"><i className="trnd trnd-stats"></i></span>
                      </a> <a type="button" href="https://torn.community/" target="_blank" rel="noreferrer"
                        className="button is-icon">
                        
                        <span className="icon is-small"><i className="trnd trnd-discourse"></i></span>
                      </a> <a type="button" href="https://discord.com/invite/TFDrM8K42j" target="_blank"
                        rel="noreferrer" className="button is-icon">
                        
                        <span className="icon is-small"><i className="trnd trnd-discord"></i></span>
                      </a> <a type="button" href="https://BNB-cash.medium.com/" target="_blank" rel="noreferrer"
                        className="button is-icon">
                        
                        <span className="icon is-small"><i className="trnd trnd-medium"></i></span>
                      </a> <a type="button" href="https://twitter.com/BNBCash" target="_blank" rel="noreferrer"
                        className="button is-icon">
                        
                        <span className="icon is-small"><i className="trnd trnd-twitter"></i></span>
                      </a> <a type="button" href="https://t.me/BNBCashOfficial" target="_blank" rel="noreferrer"
                        className="button is-icon">
                        
                        <span className="icon is-small"><i className="trnd trnd-telegram"></i></span>
                      </a> <a type="button" href="https://github.com/BNBcash" target="_blank" rel="noreferrer"
                        className="button is-icon">
                        
                        <span className="icon is-small"><i className="trnd trnd-github"></i></span>
                      </a> 
                      {/* <a type="button" href="https://defipulse.com/BNB-cash" target="_blank" rel="noreferrer"
                        className="button is-icon">
                        
                        <span className="icon is-small"><i className="trnd trnd-dfp"></i></span>
                      </a> */}
                      <div className="break"></div>
                      <div class="dropdown dropdown-menu-animation dropdown-langs is-top-left is-mobile-modal">
                        <div role="button" tabindex="0" aria-haspopup="true" class="dropdown-trigger">
                          <button type="button" class="button is-icon" onClick={setDropdown}>
                            <span><i className={"flag-icon is-active-locale-en "+(language == "en" ? "flag-icon-gb" : "flag-icon-cn")}></i></span>
                          </button></div>
                          <div class={"dropdown-menu "+(!dropdown && "hide" )} >
                          <div role="list" class="dropdown-content"> 
                          <a role="listitem" tabindex="0" className={"dropdown-item "+(language == "en" && "is-active")} onClick={()=>closeDropdown('en')}><i class="flag-icon flag-icon-gb"></i>
                            EN
                          </a><a role="listitem" tabindex="0" className={"dropdown-item "+(language == "cn" && "is-active")} onClick={()=>closeDropdown('cn')}><i class="flag-icon flag-icon-cn"></i>
                            CN
                          </a></div></div>
                    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
    )
}
export default Footer;