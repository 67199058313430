import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';
import './assets/css/common.css'
import { useEagerConnect, useInactiveListener } from './hooks/useEagerConnect';
import { Home,Cash, Account } from './screen';
import useLink from './hooks/useLink'
import { logo } from './components/Images';

function App() {
  const [errorMessage, setErrorMessage] = useState();
  useEagerConnect(setErrorMessage);
  useInactiveListener();
  const [loader,setLoader] = useState(true);
  setTimeout(() => {
    setLoader(false)
  }, 1000);
  return (
    <div className="App">
      {
        loader &&
        <div className='loader-section'>
          <img src={logo}/>
        </div>
      }
      
      {
        errorMessage? <div style={{color:"red"}}>{errorMessage}</div>: null
      }
      <div id="__nuxt" className={loader && "hide"}>
   
    <div id="__layout">
     
      <div className="wrapper">
      <BrowserRouter>
      
      <section className="main-content section">
      <div className="container">
      <div>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/cash' element={<Cash />} />
            <Route path='/account' element={<Account />} />

          </Routes>
          </div>
          
          </div>
      </section>
        </BrowserRouter>

       </div>
    </div>
    </div>
  </div>
  );
}

export default App;
