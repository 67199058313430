import { useWeb3React } from "@web3-react/core";
import { Link, NavLink } from "react-router-dom"
import { connectWallet } from "../utils/connectWallet";
import {   work1, work2,  work3,  } from "./Images"
import { memo , useState } from "react"
import useTranslation from "../hooks/useTranslation";
import parse from 'html-react-parser'

function Work(){
  const {t} = useTranslation()

    return(
        <div className="BNB-section how-to-work">
        <h2 className="title is-spaced">{parse(t('work.title'))}</h2>
        <div className="work">
            {/* <img src={work1}/>
            <img src={work2}/>
            <img src={work3}/> */}
        </div>
        <div className="columns is-justify-content-space-between">
          <div className="column is-block">
            <h3 className="title is-primary is-5">{t('work.deposit')}</h3>
            <p className="subtitle">{t('work.deposit_desc')}</p>
          </div>
          <div className="column is-block">
            <h3 className="title is-primary is-5">{t('work.wait')}</h3>
            <p className="subtitle">{t('work.wait_desc')}</p>
          </div>
          <div className="column is-block">
            <h3 className="title is-primary is-5">{t('work.withdraw')}</h3>
            <p className="subtitle">{t('work.withdraw_desc')}</p>
          </div>
        </div>
      </div>
    )

}
export default memo(Work);