import useAsync from "./useAsync";


export default function useLink(href) {
    return useAsync(() => {
        const link = document.createElement("link")
        link.rel = 'stylesheet'
        link.href = href;

        return new Promise((resolve, reject) => {
            link.addEventListener("load", resolve)
            link.addEventListener("error", reject)
            document.head.appendChild(link)
        })
    }, [href])
}