import  Banner  from "../components/Banner"
import  Work  from "../components/Work"
import  Privacy  from "../components/Privacy"
import  Box  from "../components/Box"
import  Statuss  from "../components/Status"
import  Products  from "../components/Products"
import  Tokenomics  from "../components/Tokenomics"
import  Header  from "../components/Header"
import useLink from "../hooks/useLink"
import Footer from '../components/Footer'

function Home() {
  useLink(`${process.env.PUBLIC_URL}/style.css`)

    return <main>
          <Header />
          <Banner />
          <Work />
          <Privacy />
          <Box />
          <Statuss />
          <Products />
          <Tokenomics />
          <Footer />

    </main>
}

export default Home