import { useWeb3React } from "@web3-react/core";
import useTranslation from "../hooks/useTranslation";
import { connectWallet } from "../utils/connectWallet";

export default function MetamaskModal(props)
{
    const {
        active,
        activate,
        account
    } = useWeb3React();
    const {t} = useTranslation();
    const closeModal = () => {
        props.setIsShow(false)
        connectWallet(activate, props.setErrorMessage)
    }
    return <>
    <div tabindex="-1" class={"modal "+(props.isShow && "is-active")} id="mining_modal">
    <div class="modal-background"></div>
    <div class="animation-content" style={{maxWidth: "440px"}}>
        <div class="modal-card box box-modal is-wallet-modal" can-cancel="escape,button,outside">
            <header class="box-modal-header is-spaced">
                <div class="box-modal-title">{t('your_wallet')}</div> <button type="button" class="delete" onClick={(e)=>props.setIsShow(false)}></button>
            </header>
            <div class="note">
                {t('select_wallet')}
            </div>
            <div class="field is-grouped is-grouped-centered is-grouped-multiline wallets">
                <div class="control">
                    <button class="button is-small is-background is-metamask" onClick={()=>closeModal()}>
                        Metamask
                    </button> </div>
            </div>
        </div>
    </div>
    </div>
    </>
}