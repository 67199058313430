import { useWeb3React } from "@web3-react/core";
import { Link, NavLink } from "react-router-dom"
import { connectWallet } from "../utils/connectWallet";
import {  logo,icon,privacy_img  } from "./Images"
import { memo , useState } from "react"
import useTranslation from "../hooks/useTranslation";
import parse from 'html-react-parser'

function Statuss() {
    const {t} = useTranslation()
    return (
        <div className="BNB-section status">
        <h2 className="title has-text-centered">{parse(t('box.title'))}</h2>
        <p className="subtitle has-text-centered">{parse(t('box.intro'))}</p>
        <div className="hexagon-container">
          <div className="hex">
            <div className="hexagon">
              <h5>
                <p>{parse(t('box.box1'))}</p>
              </h5>
            </div>
          </div>
          <div className="hex">
            <div className="hexagon">
              <h5>
                <p>{parse(t('box.box2'))}</p>
              </h5>
            </div>
          </div>
          <div className="hex">
            <div className="hexagon">
              <h5>
                <p>{parse(t('box.box3'))}</p>
              </h5>
            </div>
          </div>
          <div className="hex">
            <div className="hexagon">
              <h5>
                <p>{parse(t('box.box4'))}</p>
              </h5>
            </div>
          </div>
          <div className="hex">
            <div className="hexagon">
              <h5>
                <p>{parse(t('box.box5'))}</p>
              </h5>
            </div>
          </div>
            <div className="hex">
              {/* <!-- <div className="hexagon">
                <h5>
                  <p>Trusted setup ceremony for zkSNARKs has 1114 contributions, as long as at least 1
                    contribution is honest, the zkSNARK keys are secure.</p>
                  </h5>
                </div> --> */}
          </div>
          <div className="hex">
            <div className="hexagon">
              <h5>
                <p>{parse(t('box.box6'))}</p>
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
}

export default memo(Statuss);