import { useWeb3React } from "@web3-react/core";
import { Link, NavLink } from "react-router-dom"
import { connectWallet } from "../utils/connectWallet";
import {  banner_icon, icon  } from "./Images"
import { memo , useEffect, useState } from "react"
import useTranslation from "../hooks/useTranslation";

function Banner(){
    const {t} = useTranslation()

    return(
        <div className="BNB-section main">
            <div className="columns is-vcentered">
                <div className="column has-text-left">
                    <h1 className="title is-primary">{t('title')}</h1>
                    <p className="subtitle">{t('banner.intro')}</p>
                    <div className="field"><a href={'/cash'} target="_blank"
                        rel="noreferrer" className="button is-primary is-outlined"><span className="icon is-small"><i
                            className="trnd trnd-BNB"><img src={icon} alt="" /></i></span><span>
                         {t('nav.launch_app')}
                        </span>
                    
                    </a></div>
                </div>
                <div className="column">
                    <figure className="image is-main"><img src={banner_icon} /></figure>
                </div>
            </div>
      </div>
    )

}
export default memo(Banner);