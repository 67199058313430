import { useWeb3React } from "@web3-react/core";
import { Link, NavLink } from "react-router-dom"
import { connectWallet } from "../utils/connectWallet";
import {  tokenomic,icon,privacy_img  } from "./Images"
import { memo , useState } from "react"
import parse from 'html-react-parser'
import useTranslation from "../hooks/useTranslation";

function Tokenomics(){
  const {t} = useTranslation()

    return (
        <div className="BNB-section faq">
                <h2 id="faq" className="title is-spacedx has-text-centered">{t('tokennomics')}</h2>
                <div className="questions">
                   <img src={tokenomic} alt="" />
          
                </div> 
              </div>
    )
}

export default memo(Tokenomics);