import { useState } from "react";
import * as translations from "../translation"
import useLocalStorage from "./useLocalStorage";
function useTranslation()
{
    const [language, setLanguage] = useLocalStorage("language", "en");
    const translate = key => {
        const keys = key.split(".")
        return (
            getNestedTranslation(language, keys) ??
            getNestedTranslation(language, keys) ??
            key
        )
    }

    return {
        language,
        setLanguage,
        t: translate,
    }
}
function getNestedTranslation(language, keys) {
    return keys.reduce((obj, key) => {
        return obj?.[key]
    }, translations[language])
}
export default useTranslation