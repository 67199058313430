import { useWeb3React } from "@web3-react/core";
import { Link, NavLink } from "react-router-dom"
import { connectWallet } from "../utils/connectWallet";
import {  product1,product2,product3  } from "./Images"
import { memo , useState } from "react"
import useTranslation from "../hooks/useTranslation";
import parse from 'html-react-parser'

function Products()
{
  const {t} = useTranslation()
    return (
        <div className="BNB-section our-products">
        <h2 className="title is-spaced">{t('product.title')}</h2>
        <div className="products is-multiline columns">
          <div className="column is-half-desktop">
            <div className="product">
              <figure className="image is-product"><img src={product1} /></figure>
              <div className="product-body has-text-left">
                <h4 className="title is-primary is-5">{t('product.administration')}</h4>
                <p className="subtitle">{parse(t('product.administration_desc'))}</p>
              </div>
            </div>
          </div>
          <div className="column is-half-desktop">
            <div className="product">
              <figure className="image is-product"><img src={product2} /></figure>
              <div className="product-body has-text-left">
                <h4 className="title is-primary is-5">{t('product.mining')}</h4>
                <p className="subtitle">{parse(t('product.mining_desc'))}</p>
              </div>
            </div>
          </div>
          <div className="column is-half-desktop">
            <div className="product">
              <figure className="image is-product"><img src={product3} /></figure>
              <div className="product-body has-text-left">
                <h4 className="title is-primary is-5">{parse(t('product.consistence'))}</h4>
                <p className="subtitle">{parse(t('product.consistence_desc'))}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default memo(Products);