import  Cashs  from "../components/Cash"
import  Header2  from "../components/Header2"
// import  '.././assets/css/eth.css'
import useLink from "../hooks/useLink"
import Footer2 from "../components/Footer2"

function Cash() {
    useLink(`${process.env.PUBLIC_URL}/eth.css`)
    return <main>
        <Header2 />
          <Cashs />
        <Footer2/>
          
    </main>
}

export default Cash